import React from 'react';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

import SoftwareList from 'Components/software/List';

export default ({ data }) => {
  const { edges: software } = data.allMarkdownRemark;

  return (
    <PageWrapper>
      <SEO title="Software" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Software</h1>
        <SoftwareList softwares={ software } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "software"},
        }
      },
      sort: {
        fields: frontmatter___name,
        order: ASC
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            category
            order
            url
            excerpt
            logo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
