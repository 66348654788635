import React, { useEffect } from 'react';

import _ from 'lodash';

import SoftwareCard from './Card';

const SoftwareList = ({ softwares }) => {
  const byCategory = _.groupBy(softwares, ({ node: software }) => software.frontmatter.category);

  useEffect(() => {
    const lists = document.querySelectorAll('.software__list');

    lists.forEach(list => {
      const cards = list.children;
      const cardsArray = Array.prototype.slice.call(cards, 0);
      const orderedCards = cardsArray.sort((a, b) => +a.id.match(/\d+/) - +b.id.match(/\d+/));
      orderedCards.forEach(card => { list.appendChild(card); });
    });
  }, []);

  return (
    <div className="software__wrapper">
      { Object.keys(byCategory).sort().map(category => (
        <div className="software__list__wrapper" key={ category } id={ category }>
          <h3 className="page__subtitle">{ category }</h3>
          <div className="software__list">
            { !!byCategory[category] && byCategory[category].map(({ node: software }) => (
              <SoftwareCard
                key={ software.id }
                software={ software }
                order={ software.frontmatter.order }
              />
            )) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default SoftwareList;
