import React from 'react';

const SoftwareCard = ({ software, order }) => (
  <div className="software__card" id={ order }>
    { !!software?.frontmatter?.logo?.childImageSharp && (
      <a href={ software.frontmatter.url } target="blank" className="software__card__img__link">
        <img className="software__card__img" src={ software.frontmatter.logo.childImageSharp.fluid.src } alt={ `Software - ${software.frontmatter.title}` } />
      </a>
    ) }
    <div className="software__card__content">
      <div className="software__card__excerpt">
        <p>{ software.frontmatter.excerpt }</p>
      </div>
      <a href={ software.frontmatter.url } target="blank" className="software__card__cta">
        Read More
      </a>
    </div>
  </div>
);

export default SoftwareCard;
